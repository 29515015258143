<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Card Transactions</h2>
      </div>
      <div class="col-span-1">
        <div class="relative">
          <search-form
            v-model="query"
            placeholder="Search for user's name, status, etc."
            @submit="(event) => $refs.table.loadAjaxData()"
          />
        </div>
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/admin/personal/cards/transactions`"
        :ajax="true"
        :onClick="goToUser"
        :fillable="true"
        :ajaxPagination="true"
        :data="transactions.data"
        :columns="transactions.columns"
        :query="query"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      query: "",
      transactions: this.$options.resource([], {
        columns: [
          {
            name: "user",
            th: "User",
            render: (transaction) => {
              if (!transaction.personal_account) {
                return "N/A";
              }
              return `${transaction.personal_account?.user?.name} ${transaction
                .personal_account?.user?.last_name || ""}`;
            },
          },
          {
            name: "company",
            th: "Company",
            render: (transaction) =>
              `${transaction?.personal_account?.user?.company?.name || ""}`,
          },
          {
            name: "cardtype",
            th: "Card Type",
            render: (transaction) => {
              if (transaction.wallet_type === 'credpal_card') {
                      return 'Credit card';             
              }
               return 'Cash';
            }
          },
          {
            name: "type",
            th: "Transaction Type",
          },
           {
            name: "id",
            th: "Transaction ID",
          },
          {
            name: "category",
            th: "Category",
          },
          {
            name: "description",
            th: "Description",
          },
          {
            name: "amount",
            th: "Amount",
            render: (transaction, amount) => {
              if (amount) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  amount
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
          {
            name: "status",
            th: "Status",
            render: (transaction, status) => {
              switch (status) {
                case "success":
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                                Success
                              </div>`;
                case "pending":
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                                Pending
                            </div>`;
              }
            },
          },
          {
            name: "date",
            th: "Date",
            render: (transaction, date) => {
              return moment(date).format("MMM Do YYYY");
            },
          },
        ],
      }),
    };
  },
  beforeMount() {
    this.getTransactions();
  },
  methods: {
    goToUser({ personal_account }) {
      return this.$router.push({
        name: "staff-view",
        params: {
          staffId: personal_account?.user?.id,
        },
      });
    },
    async getTransactions() {
      this.transactions.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/transactions`,
        headers: this.headers,
        success: (response) => {
          this.transactions.data = response.data.transactions;
          this.transactions.loading = false;
        },
      });
    },
  },
};
</script>
